// NPM
import React from 'react'

// Shared components
import Description from 'src/components/Shared/Description'

const JobPosting = ({ careersPageHero, jobPosting }) => {
  return (
    <div className="JobPosting">
      <div className="CommonHero">
        { 
          careersPageHero &&
            <h2 className="CommonHero__title small-header">
              { careersPageHero.title }
            </h2>
        }
        <h3 className="CommonHero__description">
          { jobPosting.title }
        </h3>
      </div>

      <Description 
        description={ jobPosting.description } 
        className="JobPosting__description"
      />

      <a 
        className="JobPosting__link black-button"
        href={ `mailto:careers@voxpoplabs.com?subject=${ encodeURIComponent(`Job application: ${ jobPosting.title }`) }` }
        target="_blank">
          Apply
      </a>
    </div>
  )
}

export default JobPosting