// NPM
import * as React from "react"
import { graphql } from 'gatsby'
import _ from 'lodash'

// Components
import Layout from "src/layouts/fr"
import JobPosting from "src/components/pages/JobPosting/JobPosting"

const JobPostingPageEn = ({ location, data }) => {
  const topNav = data.topNav
  const jobPosting = _.get(data, `jobPosting`)
  const bottomNav = data.bottomNav
  const socialMedia = _.get(data, `socialMedia.nodes`, [])

  return (
    <Layout 
      location={ location }
      topNav={ topNav }
      page={ data.jobPostingPage }
      bottomNav={ bottomNav }
      socialMedia={ socialMedia }>
        <JobPosting jobPosting={ jobPosting } />
    </Layout>
  )
}

export default JobPostingPageEn

export const query = graphql`
  query JobPostingPageFrQuery($id: String) {
    topNav: strapiTopNav(locale: {eq: "fr"}) {
      ...topNavContent
    }
    
    jobPosting: strapiJobPosting(id: {eq: $id}) {
      ...jobPosting
    }

    bottomNav: strapiBottomNav(locale: {eq: "fr"}) {
      ...bottomNavContent
    }

    socialMedia: allStrapiSocialMedia(filter: {locale: {eq: "fr"}}) {
      nodes {
        ...socialMedia
      }
    }


  }
`